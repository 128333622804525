<template lang="pug">
	el-form
		el-row(:gutter="10")
			el-col(:xs="24" :sm="8")
				el-form-item(label="By Keyword" label-position="top" label-width="auto")
					el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")
			el-col(:xs="12" :sm="8")
				el-form-item(label="By CRM Type" label-position="top" label-width="auto")
					el-select.w-100(filterable="", v-model="filters.crm_type" @change="fetchData")
						el-option(value="" label="- Select CRM Type -" )
						el-option(v-for="o of crm_types_options" :value="o.value" :label="o.link")
			el-col(:xs="12" :sm="8")
				el-form-item(label="By Owner" label-position="top" label-width="auto")
					el-select.w-100(filterable="", v-model="filters.owner" @change="fetchData")
						el-option(value="" label="- Select Owner -" )
						el-option(v-for="o of brand_owners_options" :value="o.v" :label="o.t")

		el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
			el-table-column(align='center', label='#' :width='55')
				template(slot-scope='scope')
					| {{ scope.row.id }}

			el-table-column( align='center', label="Name")
				template(slot-scope="scope")
					span.chooseable() {{ scope.row.name || '-' }}
					small.text-success(v-if="scope.row.global_name")
						| &nbsp;({{ scope.row.global_name }})
			el-table-column(align='center' label='Owner')
				template(slot-scope='scope')
					el-tooltip(v-if="scope.row.user_id")
						template(slot="content")
							span.d-block <b>ID:</b> {{ scope.row.user_hash.id }}
							span.d-block <b>Username:</b> {{ scope.row.user_hash.username }}
							span.d-block <b>Name:</b> {{ scope.row.user_hash.first_name }} {{ scope.row.user_hash.last_name }}
							span.d-block <b>Email:</b> {{ scope.row.user_hash.email }}
						span.chooseable {{ scope.row.user_hash.username }}
					span.text-info(v-else="")
						i None
			el-table-column(align="center" label="Actions" :width="120" )
				template(slot-scope="scope")
					.d-flex.justify-content-center
						el-button.small-icon(v-b-tooltip.html.hover.top="", title="Filter by this brand", type="success", icon="el-icon-check", size="small", @click="chooseBrand(scope)")
						el-button.small-icon(v-b-tooltip.html.hover.top="", title="Edit brand", type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'iframe-edit-brand', params: {id: scope.row.id}})")
						//el-button.small-icon(v-b-tooltip.html.hover.right="", title="Remove Brand <br><small class='text-warning'>(Note - removing the brand will also remove the funnels)</small>", type="danger", size="small",  icon="el-icon-remove", @click="removeEntity(scope.row.id)")

		//ds-paginate(:limit="paginate.limit", :page.sync="paginate.page", :count="count", :func="fetchData")
		.d-flex.justify-content-between
			b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


			el-row
				el-col(:span="24")
					el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
						el-option(label="10 Per Page", :value="10")
						el-option(label="20 Per Page", :value="20")
						el-option(label="50 Per Page", :value="50")
						el-option(label="100 Per Page", :value="100")
						el-option(label="200 Per Page", :value="200")
</template>


<style lang="scss" scoped>
.small-icon {
	padding: 9px;
}
</style>


<script lang="js">
	import Vue from 'vue';
	import DsPaginate from "../../../components/paginate";

	export default {
		components: {DsPaginate},
		name: 'brand-list',
		data() {
			return {
				busy: false,
				list: [],
				count: 0,
				paginate: {
					keyword: '',
					limit: 10,
					page: 1
				},
				filters: {
					crm_type: '',
					owner: ''
				},
				crm_types_options: [],
				brand_owners_options: []
			}
		},
		async created() {
			await this.fetchData();
			this.$apix.sendHttpRequest('GET', 'components/options', {filters: 'a371666a-a396-4137-b92c-6c1fc201e671,018e81d9-3eb1-7f9e-8464-b7fb09b5e357'})
				.then(options => {
					this.crm_types_options = options.crm_types;
					this.brand_owners_options = options.brand_owners;
				});

		},
		methods: {
			async fetchData() {
				setTimeout(() => {
					this.$apix.sendHttpRequest('GET', 'brand/list', Object.assign({}, {...this.paginate}, {...this.filters}))
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
						})
				}, 0)
			},
			chooseBrand(scope) {
				this.$store.dispatch('setCurrentBrand', { id: scope.row.id, name: scope.row.name })
				this.$emit('brand', scope.row.id);
				this.$emit('brand_name', scope.row.name)
			}
		}
	}
</script>
