<template lang="pug">
	el-form
		el-row(:gutter="10")
			el-col(:xs="24" :sm="8")
				el-form-item(label="By Keyword" label-position="top" label-width="auto")
					el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")
			el-col(:xs="12" :sm="8")
				el-form-item(label="By Funnel Family" label-position="top" label-width="auto")
					el-select.w-100(filterable="", v-model="filters.funnel_family_id" @change="fetchData")
						el-option(value="" label="- Select Funnel Family -" )
						el-option(v-for="o of funnel_families_options" :value="o.v" :label="o.t + (o.rn ? ` (${o.rn})` : '' )")
			el-col(:xs="12" :sm="8")
				el-form-item(label="By Language" label-position="top" label-width="auto")
					el-select.w-100(filterable="", v-model="filters.language_iso" @change="fetchData")
						el-option(value="" label="- Select Language -" )
						el-option(v-for="o of language_options" :value="o.v" :label="o.t")

		el-empty.p-3(description="No Records..." :image-size="50" v-if="list.length === 0")
		el-card.sheets-box(:span=24 v-for="(r,i) of list" :name="i" :body-style="{padding: 0}" shadow="never" )
			div(slot="header" class="clearfix")

				.d-flex.justify-content-between.w-100
					div.chooseable(@click="r.is_open = !r.is_open")
						el-tooltip(:content="r.in_use ? 'Integration Running' : 'Integration is off'")
							font-awesome-icon.icon.alt.text-danger.ml-2(v-if="!r.in_use", :icon="['fas', 'times']")
							font-awesome-icon.icon.alt.text-success.ml-2(v-else="", :icon="['fas', 'check']")
						b.ml-2 {{'#' + r.id}}
						b.ml-2  {{ r.brand.name}}&nbsp;
							small ({{ r.integration.type }})

						span.small.ml-4.v-desktop
							b Hash:&nbsp;
							span.hide-less-1400-el {{ r.hash }}
							span.hide-more-1400-el(v-b-tooltip.html.hover.rigt h="", :title="'Hash: ' + r.hash") {{ r.hash.substr(0, 20) }}...
						span.small.ml-4.v-desktop
							b(v-if="r.title") Title:&nbsp;
							| {{ r.title }}
						span.small.ml-4.v-mob(v-b-tooltip.html.hover.rigt h="", :title="`${r.title ? `<b>Title:</b> ${r.title}<br> ` : ''}<b>Hash: </b>${r.hash}`")
							b.hide-ultrasmall Hover to view hash
					.mr-3
						el-button.small-icon(v-b-tooltip.html.hover.left="", :title="`Toggle Spreadsheet ${r.in_use ? 'Off' : 'On'}`", :type="r.in_use ? 'warning' : 'success'", :icon="`el-icon-${r.in_use ? 'error' : 'success'}`", size="small", @click.prevent="toggleEntry(r.id, r.in_use)")
						el-button.small-icon(v-b-tooltip.html.hover.left="", title="Edit Spreadsheet", type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'iframe-edit-spreadsheet', params: { id: r.id }})")
						el-button.small-icon(v-b-tooltip.html.hover.left="" title="Clone the spreadsheet" type="warning" icon="el-icon-star-on" size="small", @click="$router.push({name: 'iframe-add-spreadsheet', query: { id: r.id }})")
			div(v-if="r.is_open")
				el-table.mb-3(id="data-list", v-loading='busy', stripe="", :data='r.worksheet', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
					el-table-column(align="center", label="#", :width="55" )
						template(slot-scope='scope')
							| {{ scope.row.id }}
					el-table-column(align="center", label="External #" )
						template(slot-scope="scope")
							span {{ scope.row.external_id || '-' }}
					el-table-column(align="center", label="In Use" :width="80")
						template(slot-scope="scope" )
							el-tooltip(:content="scope.row.in_use ? 'Worksheet integration is running' : 'Worksheet integration is off'")
								font-awesome-icon.icon.alt.text-danger.ml-2(v-if="!scope.row.in_use", :icon="['fas', 'times']")
								font-awesome-icon.icon.alt.text-success.ml-2(v-else="", :icon="['fas', 'check']")
					el-table-column(align="center", label="Language" )
						template(slot-scope="scope")
							span() {{ scope.row.language ? languagesMap[scope.row.language] : '-' }}
					el-table-column(align="center", label="Lookup Keys" )
						template(slot-scope="scope")
							span.chooseable(v-b-tooltip.html.hover.right="", :title="scope.row.keys")  Hover to view
					el-table-column(align="center", label="Link" )
						template(slot-scope="scope")
							span.chooseable(@click="openLink(`${link}${r.hash}/edit#gid=${scope.row.external_id}`)")  Click To Open
					el-table-column(align="center" label="Actions" :width="115")
						template(slot-scope="scope")
							.d-flex.justify-content-center
								el-button(v-b-tooltip.html.hover.top="", title="Show Integration", type="success", icon="el-icon-tickets", size="small", @click="$emit('googleSheet', r.integration.id)")
								//el-button(v-b-tooltip.html.hover.left="", title="Edit Spreadsheet", type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'iframe-edit-funnel', params: {id: scope.row.id}})")
								//el-button(v-b-tooltip.html.hover.top="", title="Remove Spreadsheet", type="danger", size="small",  icon="el-icon-remove", @click="removeEntity(r.integration.id)")
			el-divider.m-1
		.d-flex.justify-content-between
			b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


			el-row
				el-col(:span="24")
					el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
						el-option(label="5 Per Page", :value="5")
						el-option(label="10 Per Page", :value="10")
						el-option(label="20 Per Page", :value="20")
						el-option(label="50 Per Page", :value="50")
						el-option(label="100 Per Page", :value="100")
						el-option(label="200 Per Page", :value="200")
</template>

<style lang="scss">
.sheets-box {
	border: none;
	font-size: 14px;
	.el-card__header {
		padding: 5px;
		border-bottom: none;
	}
}
</style>

<style lang="scss" scoped>
.sheets-box {
	.el-card__header {
		padding-top: 5px
	}
}
.small-icon {
	padding: 9px;
}

.hide-less-1400-el {
	@media only screen and (max-width: 1499px) {
		display: none
	}
}
.hide-more-1400-el {
	@media only screen and (min-width: 1500px) {
		display: none;
	}
}
</style>

<script lang="js">
	const clearilters = (data) => {
		let body = {...data};

		if(!body.brand_id) delete body.brand_id;
		return {...body}
	};

	export default {
		name: 'google-sheets-list',
		props: ['brand_id'],
		data() {
			return {
				busy: false,
				activeEntries: [],
				list: [],
				count: 0,
				languagesMap: {},
				paginate:{
					keyword: '',
					limit: 10,
					page: 1
				},
				filters: {
					funnel_family_id: '',
					language_iso: ''
				},
				language_options: [],
				funnel_families_options: []
			}
		},
		async created() {
			await this.fetchData();
			// this.languagesMap = await this.getLanguages();
			await this.getOptions();
		},
		watch: {
			async brand_id() {
				await this.fetchData();
			}
		},
		computed: {
			link() {
				return process.env.VUE_APP_GOOGLE_SHEETS_URL || 'https://docs.google.com/spreadsheets/d/'
			}
		},
		methods: {
			async getOptions() {
				return this.$apix.sendHttpRequest('GET', 'components/options', {filters: 'e91556b8-c5da-442f-a0bf-31f2ce567abe,95e5744a-9964-42d4-ae26-c3e591ecb7e0'})
						.then(options => {
							const hash = {};
							console.log('[options]', options);
							options.languages.forEach(l => hash[l.v] = l.t);
							this.languagesMap = hash;
							this.language_options = options.languages;
							this.funnel_families_options = options.funnel_families;
						})
			},
			openLink(url) {
				window.open(url, "_blank")
			},
			toggleCollapse(event) {
				// console.log('event', event)
			},
			async fetchData() {
				setTimeout(() => {
					this.$apix.sendHttpRequest('GET', 'spreadsheet/list', clearilters(Object.assign({}, {...this.paginate}, {...this.filters, brand_id: this.brand_id})))
						.then(res => {
							this.list = res.rows.map(e => ({...e, is_open: false}));
							this.count = res.count;
						})
				}, 0)
			},
			toggleEntry(id, in_use) {
				this.$confirm('This will toggle the spreadsheet on/off. Continue?', 'Warning', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}).then(() => {
					this.$apix.sendHttpRequest('PUT', `spreadsheet/toggle/${id}/${+!in_use}`, {})
						.then(() => {
							this.$notify.success({title: 'API Response', message: 'Spreadsheet toggled successfully'})
							this.fetchData()
						})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: 'Cancelled'
					});
				});
			}
		}
	}
</script>
