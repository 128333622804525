<template lang="pug">
	.widget
		el-card.box-card

			el-tabs(v-model="currentTab" type="" @tab-click="changeTabFunc")
				el-tab-pane(label="iFrame" name="first")

					el-row.mt-3(:gutter="48")
						el-col(:md="24" :lg="9")
							el-row
								el-col.text-left(:span="24")
									.d-flex.justify-content-between.v-desktop
										h3
											b Brands
										.d-flex.justify-content-between
											el-tooltip(content="Add new brand")
												el-button(type="success", icon="el-icon-plus", size="mini", @click="$router.push({name:'iframe-add-brand'})") Brand
											el-tooltip(content="Add new integration")
												el-button(type="warning", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-integration'})") Integration
											el-tooltip(content="Add new Funnel")
												el-button(type="danger", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-funnel'})") Funnel
									.v-mob
										.d-flex.justify-content-start
											el-button(v-b-tooltip.html.hover.top="", title="Add new brand", type="success", icon="el-icon-plus", size="mini", @click="$router.push({name:'iframe-add-brand'})") Brand
											el-button(v-b-tooltip.html.hover.top="", title="Add new integration", type="warning", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-integration'})") Integration
											el-button(v-b-tooltip.html.hover.top="", title="Add new Funnel", type="danger", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-funnel'})") Funnel
										h3.mt-4
											b Brands
							el-row
								el-col(:span="24")
									brand-list(@brand="($event) => this.brand = $event" @brand_name="($event) => this.brand_name = $event")

						el-col.gap-1200(:md="24" :lg="15")
							el-row
								el-col.text-left(:span="24")
									.d-flex.justify-content-between.v-desktop
										h3
											b Funnels -&nbsp;
											small ({{ brand_name || 'All' }})
										.d-flex.justify-content-between
											el-tooltip(:content="!bulkFunnelsSelected ? 'Open a bulk update modal' : 'Select funnels to update their pros'")
												el-button(type="primary", size="small", @click="() => {this.showFunnelBulkUpdateModal=!this.showFunnelBulkUpdateModal}" :disabled="bulkFunnelsSelected") Bulk Update

											el-tooltip(content="Show All Control Groups - Working hours and geos")
												el-button(type="info" size="mini" @click="() => {this.showControlGroupsModal=!this.showControlGroupsModal}") Control Groups
											el-tooltip(content="Show All Niches")
												el-button(type="warning", size="small", @click="() => {this.showNicheModal=!this.showNicheModal}") Niche
											el-tooltip(content="Show All Funnel Families and the related entities")
												el-button(type="success", size="small", @click="() => {this.showFunnelFamilyModal=!this.showFunnelFamilyModal}") Funnel Families
											el-tooltip(content="Show All Integrations and the related entities")
												el-button(type="warning", size="small", @click="() => {this.showIntegrationsModal=!this.showIntegrationsModal}") List Integrations
											el-tooltip(content="Show All Brands - Clear the brand selection")
												el-button(type="info", size="small", @click="clearBrands") All Funnels
									.v-mob
										el-divider.mb-4
										.d-flex.justify-content-start
											el-tooltip(content="Show funnel families")
												el-button(type="success", size="small", @click="() => {this.showFunnelFamilyModal=!this.showFunnelFamilyModal}") Funnel Families
											el-tooltip(content="Show all integrations")
												el-button(type="warning", size="small", @click="() => {this.showIntegrationsModal=!this.showIntegrationsModal}") List Integrations
											el-tooltip(content="Show all brands - Clear brand selection")
												el-button(type="info", size="small", @click="clearBrands") All Funnels

										h3.mt-4
											b Funnels -&nbsp;
											small ({{ brand_name || 'All' }})

							el-row
								el-col(:span="24")
									funnel-list(@funnel="($event) => {this.integrationsDialogShown = !this.integrationsDialogShown; this.funnel = $event; this.type='funnel'}" :brand_id="brand" :brand_name="brand_name")

				// Google Sheets
				el-tab-pane(label="Google Sheets" name="second")
					el-row.mt-3(:gutter="48")
						el-col(:md="24" :lg="9")
							el-row
								el-col.text-left(:span="24")
									.d-flex.justify-content-between.v-desktop
										h3
											b Brands
										.d-flex.justify-content-between
											el-tooltip(content="Add new brand")
												el-button(type="success", icon="el-icon-plus", size="small", @click="$router.push({name:'iframe-add-brand'})") Brand
											el-tooltip(content="Add new integration")
												el-button(type="warning", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-integration'})") Integration
											el-tooltip(content="Add new Spreadsheet or Worksheet")
												el-button(type="danger", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-spreadsheet'})") Spreadsheet
									.v-mob
										.d-flex.justify-content-start
											el-button(v-b-tooltip.html.hover.top="", title="Add new brand", type="success", icon="el-icon-plus", size="small", @click="$router.push({name:'iframe-add-brand'})") Brand
											el-button(v-b-tooltip.html.hover.top="", title="Add new integration", type="warning", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-integration'})") Integration
											el-button(v-b-tooltip.html.hover.top="", title="Add new Spreadsheet or Worksheet", type="danger", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-spreadsheet'})") Spreadsheet
										h3.mt-4
											b Brands
							el-row
								el-col(:span="24")
									brand-list(@brand="($event) => this.brand = $event"  @brand_name="($event) => this.brand_name = $event")

						el-col.gap-1200(:md="24" :lg="15")
							el-row
								el-col.text-left(:span="24")
									.d-flex.justify-content-between.v-desktop
										h3
											b Google Sheets&nbsp;
											small ({{ brand_name || 'All' }})
										.d-flex.justify-content-between
											el-tooltip(content="Show Funnel Families")
												el-button(type="warning", size="small", @click="() => {this.showNicheModal=!this.showNicheModal}") Niche
											el-tooltip(content="Show Niches")
												el-button(type="success", size="small", @click="() => {this.showFunnelFamilyModal=!this.showFunnelFamilyModal}") Funnel Families
											el-tooltip(content="Show all integrations")
												el-button(type="warning", size="small", @click="() => {this.showIntegrationsModal=!this.showIntegrationsModal}") List Integrations
											el-tooltip(content="Show all brands - Clear brand selection")
												el-button(type="info", size="small", @click="clearBrands") All Spreadsheets
									.v-mob
										el-divider.mb-4
										.d-flex.justify-content-start
											el-button(v-b-tooltip.html.hover.top="", title="Show all integrations", type="warning", size="small", @click="() =>  {this.showIntegrationsModal=!this.showIntegrationsModal}") List Integrations
											el-button(v-b-tooltip.html.hover.top="", title="Show all brands - Clear brand selection", type="info", size="small", @click="clearBrands") All Spreadsheets
										h3.mt-4
											b Google Sheets&nbsp;
											small (SpreadSheets)

							el-row
								el-col(:span="24")
									google-sheets-list(@googleSheet="($event) => {this.integrationsDialogShown = !this.integrationsDialogShown; this.googleSheet = $event; this.type='googleSheets'}" :brand_id="brand")
									//funnel-list(@funnel="($event) => {this.integrationsDialogShown = !this.integrationsDialogShown; this.funnel = $event}" :brand_id="brand")

				el-tab-pane(label="Campaigns" name="third")
					el-row.mt-3(:gutter="48")
						el-col(:span="24")
							el-row
								el-col.text-left(:xs="24" :md="12")
									.d-flex.justify-content-between
										h3
											b Campaigns
										.d-flex.justify-content-between
											el-tooltip(content="Add new campaign")
												el-button(type="success", icon="el-icon-plus", size="small", @click="$router.push({name:'iframe-add-campaign'})") Campaign
											el-tooltip(content="Add new integration")
												el-button(type="warning", icon="el-icon-plus", size="small", @click="$router.push({name: 'iframe-add-integration'})") Integration
											el-tooltip(content="Show all integrations")
												el-button(type="info", icon="el-icon-guide" size="small", @click="() => {this.showIntegrationsModal=!this.showIntegrationsModal}") List Integrations



							el-row
								el-col(:span="24")
									campaign-list()
		el-dialog(title='Integrations List', :visible.sync='showIntegrationsModal', width='85%', center='')
			integration-modal
			.d-flex.justify-content-center.mt-3
				el-button(type='danger', @click='showIntegrationsModal = false') Close

		el-dialog(title='Funnel Family List', :visible.sync='showFunnelFamilyModal', width='85%', center='')
			funnel-family-list
			.d-flex.justify-content-center.mt-3
				el-button(type='danger', @click='showNicheModal = false') Close
		el-dialog(title='Funnel Family List', :visible.sync='showNicheModal', width='85%', center='')
			niche-list
			.d-flex.justify-content-center.mt-3
				el-button(type='danger', @click='showNicheModal = false') Close

		el-dialog(title='Control-Groups List', :visible.sync='showControlGroupsModal', width='85%', center='')
			control-groups-list(v-if="showControlGroupsModal")
			.d-flex.justify-content-center.mt-3
				el-button(type='danger', @click='showControlGroupsModal = false') Close



		el-dialog(title='Integrations', :visible.sync='integrationsDialogShown', width='80%', center='')
			integration-list(v-if="type" :type="type" :id="googleSheet || funnel" ref="intg")
			span.dialog-footer(slot='footer')
				el-button(type='primary', @click='integrationsDialogShown = false') Ok
				el-button(type='info', @click="$router.push({name: 'iframe-edit-integration', params: {id: $refs.intg.entity.id}})") Edit

		el-dialog(title="Funnel Props Bulk Update" :visible.sync="showFunnelBulkUpdateModal" width="80%" center="")
			funnel-update-modal(v-if="showFunnelBulkUpdateModal" ref="_modalFunBulkUpd" @funnelBulkUpdate="handleFunnelBulkUpdate")
			.d-flex.justify-content-center.mt-3
				el-button(type='success', @click='submitFunnelBulkPropUpdate') Submit
				el-button(type='danger', @click='showFunnelBulkUpdateModal = false') Close

		//el-row.mt-4(:gutter="12")
			el-col(:sm="24")
				el-card.box-card
					.clearfix.d-flex.justify-content-start(slot="header")
						el-col.text-left(:span="24")
							h3
								b Funnels
					el-row
						el-col(:span="24")
							funnel-list
</template>


<style lang="scss">
	.gap-1200 {
		@media only screen and (max-width: 1199px) and (min-width: 640px) {
			margin-top: 3rem;
		}


	}

	.override-argin {
		.el-dialog {
			margin-top: 9vh !important;
		}

		.ps {
			/*height: 65vh*/
		}
	}
</style>


<script lang="js">
	import BrandList from './list-components/brand';
	import FunnelList from './list-components/funnel';
	import IntegrationList from './list-components/integration';
	import GoogleSheetsList from './list-components/google-sheets';
	import IntegrationModal from './list-components/integrations-modal';
	import CampaignList from './list-components/campaign';
	import FunnelFamilyList from './list-components/funnel-families-modal';
	import NicheList from './list-components/niche-modal';
	import FunnelUpdateModal from "./form-components/funnel-update-modal.vue";
	import ControlGroupsList from "@/views/iframe-form/list-components/control-groups-list.vue";

	export default {
		components: {
			BrandList,
			FunnelList,
			IntegrationList,
			GoogleSheetsList,
			IntegrationModal,
			CampaignList,
			FunnelFamilyList,
			NicheList,
			FunnelUpdateModal,
			ControlGroupsList,
		},
		data() {
			return {
				showFunFamModal: false,
				showIntegrationsModal: false,
				showFunnelFamilyModal: false,
				showFunnelBulkUpdateModal: false,
				showControlGroupsModal: false,
				showNicheModal: false,
				type: '',
				currentTab: this.$store.getters.CUR_INTEGRATION_TAB,
				integrationsDialogShown: false,
				brand_name: '',
				brand: null,
				funnel: null,
				googleSheet: null
			}
		},
		created() {
			this.brand = this.$store.getters.CUR_BRAND_GETTER
			this.brand_name = this.$store.getters.CUR_BRAND_NAME_GETTER
		},
		computed: {
			bulkFunnelsSelected() {
				return !this.$store.getters.ITG_SELECTED_FUNNELS_FLAG
			}
		},
		methods: {
			submitFunnelBulkPropUpdate() {
				// todo - add a function that launches the function in the child component - FUM
				this.$refs._modalFunBulkUpd.submit();
			},
			handleFunnelBulkUpdate($event) {
				if($event) {
					this.$store.dispatch('clearCurrentBrand')
					this.showFunnelBulkUpdateModal = false;
				}
			},
			changeTabFunc(e) {
				this.$store.dispatch('setCurrentTab', e.name)
				this.funnel=null;
				this.googleSheet=null
			},
			clearBrands() {
				this.brand=null;
				this.brand_name='';
				this.$store.dispatch('clearCurrentBrand')
			}
		}
	}
</script>
